<template>
  <div class="adminOverview">
    <div class="grid">
      <div>
        <el-card shadow="hover" style="height:100%">
        <h4 style="margin-top: 0">用户数量</h4>
        <span class="contentText">{{ userCount.user }}</span>

        <template #footer>
          <div id="userDitail">
          <el-text type="info">学生 : {{ userCount.student }}</el-text>
          <el-text type="info">教师 : {{ userCount.teacher }}</el-text>
          <el-text type="info">管理 : {{ userCount.admin }}</el-text>
          </div>
        </template>
      </el-card>
      </div>
      <div>
        <el-card shadow="hover">
        <h4 style="margin-top: 0">班级数量</h4>
        <span class="contentText">{{ classCount }}</span>
        <template #footer>
          <div style="text-align: right">
          <el-button plain @click="emits('tab',3)" type="primary">详情</el-button>
          </div>
        </template>
      </el-card>
      </div>
      <div>
        <el-card shadow="hover">
        <h4 style="margin-top: 0">题库数量</h4>
        <span class="contentText">{{ prolemCount }}</span>
        <template #footer>
          <div style="text-align: right">
          <el-button plain @click="emits('tab',5)" type="primary">详情</el-button>
          </div>
        </template>
      </el-card>
      </div>
      <NewestProblem />
      <NewestDiscussion class="wide-card"/>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted,defineEmits } from "vue";
import { api } from "@/utils/requests";
import NewestProblem from "@/component/NewestProblem.vue";
import NewestDiscussion from "@/component/NewestDiscussion.vue";
let emits=defineEmits(["tab"])
let userCount = reactive({
  user: 0,
  student: 0,
  teacher: 0,
  admin: 0,
});
let classCount=ref(0)
let prolemCount = ref(0);
onMounted(() => {
  api.get("/problem/count").then((res) => {
    if (res.data.code == 200) {
      prolemCount.value = res.data.data
    }
  })
  api.get("/user/count").then((res) => {
    if (res.data.code == 200) {
      Object.assign(userCount, res.data.data);
    }
  });
  api.get("/class/count").then((res) => {
    if (res.data.code == 200) {
      classCount.value=res.data.data
    }
  })
});
</script>

<style scoped>
.adminOverview {
  padding: 10px 20px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 10px;
}

/* 添加样式以跨两列 */
.wide-card {
  grid-column: span 2;
}

@media (max-width: 965px) {
  /* 当屏幕宽度小于350px时，让每个el-card占100%宽度 */
  .grid {
    grid-template-columns: 100%;
  }

  /* 在小屏幕上恢复为默认布局 */
  .wide-card {
    grid-column: auto;
  }
}
.contentText{
  font-size: 2em;
  font-weight: bold;
  color: gray;
}
.el-card__footer{
  padding: 0;
}
#userDitail{
  display: flex;
}
#userDitail>*{
  flex: 1;
  font-weight: 600;
}
</style>
