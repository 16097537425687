<template>
  <div class="adminProblem">
    <div>
      <el-form inline>
        <el-form-item>
          <el-input v-model="filterInfo.problemId" placeholder="问题ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.title" placeholder="标题" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.description" placeholder="描述" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.path" placeholder="路径" />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filterInfo.start"
            type="datetime"
            placeholder="from"
          />
          <span style="margin: 0 10px">-</span>
          <el-date-picker
            v-model="filterInfo.end"
            type="datetime"
            placeholder="to"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="filterChange">筛选</el-button>
          <el-button @click="resetFilter">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData.list"
      style="width: 100%"
      @row-click="handleRowClick"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
    >
      <el-table-column
        type="selection"
        :selectable="(row, index) => true"
        width="55"
      />
      <el-table-column
        prop="problemId"
        sortable="custom"
        label="问题ID"
        width="100px"
      />
      <el-table-column prop="title" sortable="custom" label="标题" />
      <el-table-column prop="description" sortable="custom" label="描述" />
      <el-table-column prop="path" sortable="custom" label="路径" />
      <el-table-column prop="time" sortable="custom" label="时间">
        <template #default="scope">
          {{ new Date(scope.row.time).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column prop="tags" label="标签" />
      <el-table-column align="right" width="300">
        <template #header>
          <el-button type="primary" @click="handleAdd">新增</el-button>
          <el-button
            type="danger"
            :disabled="multipleSelection.length === 0"
            @click="handleMultipleDelete"
          >
            删除选中
          </el-button>
        </template>
        <template #default="scope">
          <el-button
            size="small"
            @click.stop="handleEdit(scope.$index, scope.row)"
          >
            Edit
          </el-button>
          <el-button
            size="small"
            type="danger"
            @click.stop="handleDelete(scope.row)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next, total, sizes"
        :total="tableData.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        @prev-click="handlePrev"
        @next-click="handleNext"
      />
    </div>
    <!-- 新增 -->
    <el-dialog
      id="dialog"
      v-model="problemDialog"
      :title="problemDialogType === 1 ? '新增问题' : '修改问题'"
      width="400px"
      @close="problemDialogClose"
    >
      <el-form>
        <el-form-item v-if="problemDialogType === 2">
          <el-input
            disabled
            v-model="problemDialogInfo.problemId"
            placeholder="问题ID"
          />
        </el-form-item>
        <el-form-item>
          <el-input v-model="problemDialogInfo.title" placeholder="标题" />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="problemDialogInfo.description"
            placeholder="描述"
          />
        </el-form-item>
        <el-form-item v-if="problemDialogType === 2">
          <el-input
            disabled
            v-model="problemDialogInfo.path"
            placeholder="路径"
          />
        </el-form-item>
        <el-form-item v-if="problemDialogType === 2">
          <el-date-picker
            disabled
            v-model="problemDialogInfo.time"
            type="datetime"
            placeholder="时间"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item>
          <el-input v-model="problemDialogInfo.tags" placeholder="标签" />
        </el-form-item>
        <el-form-item v-if="problemDialogType === 1">
          <label>上传文件夹：</label>
          <input type="file" ref="folderInput" webkitdirectory="true" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="problemDialog = false">取消</el-button>
        <el-button
          type="primary"
          style="float: right"
          @click="handleProblemDialogSubmit"
          :disabled="problemSubmitBtnDisabled"
        >
          {{ problemDialogType === 1 ? "新增" : "修改" }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { api } from "@/utils/requests";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import isEqual from "lodash/isEqual";

let page = ref(1);
let limit = ref(10);
let tableData = reactive({
  total: 0,
  list: [],
});
let sortInfo = reactive({
  prop: "",
  order: 0,
});
let filterInfo = reactive({
  problemId: "",
  title: "",
  description: "",
  start: null,
  end: null,
  tags: "",
  path: "",
});

const getProblemList = () => {
  api
    .post("/problem/list", { page, limit, sortInfo, filterInfo })
    .then((res) => {
      if (res.data.code === 200) {
        Object.assign(tableData, res.data.data);
      } else {
        ElMessage.error(res.data.code + ":" + res.data.message);
      }
    });
};

const handleSortChange = (e) => {
  sortInfo.prop = e.prop;
  sortInfo.order =
    e.order === "ascending" ? 1 : e.order === "descending" ? -1 : 0;
  getProblemList();
};

const filterChange = () => {
  getProblemList();
};

const resetFilter = () => {
  filterInfo.problemId = "";
  filterInfo.title = "";
  filterInfo.description = "";
  filterInfo.start = null;
  filterInfo.end = null;
  filterInfo.tags = "";
  filterInfo.path = ""; // Reset the path field
  getProblemList();
};

const handleCurrentChange = (e) => {
  page.value = e;
  getProblemList();
};

const handleSizeChange = (e) => {
  limit.value = e;
  page.value = 1;
  getProblemList();
};

const handlePrev = (e) => {
  page.value = e;
  getProblemList();
};

const handleNext = (e) => {
  page.value = e;
  getProblemList();
};

let currentRow = ref(null);
let multipleSelection = ref([]);

const handleRowClick = (e) => {
  currentRow.value = e;
  Object.assign(problemDialogInfo, e);
  problemDialogType.value = 2;
  problemDialog.value = true;
};

const handleEdit = (index, row) => {
  handleRowClick(row);
};

const handleSelectionChange = (e) => {
  multipleSelection.value = e;
};

let problemDialog = ref(false);
let problemDialogType = ref(1);
let problemDialogInfo = reactive({
  problemId: "",
  title: "",
  description: "",
  time: null,
  tags: "",
  path: "", // Add the path field
});

const problemSubmitBtnDisabled = computed(() => {
  return problemDialogType === 2 && isEqual(problemDialogInfo, currentRow);
});

const handleAdd = () => {
  problemDialogInfo.time = new Date();
  problemDialogType.value = 1;
  problemDialog.value = true;
};
let folderInput = ref(null);
const handleProblemDialogSubmit = () => {
  if (problemDialogType.value === 2) {
    api.post("/problem/modify", problemDialogInfo).then((res) => {
      if (res.data.code === 200) {
        ElMessage.success({
          message: "操作成功",
          grouping: true,
        });
        problemDialog.value = false;
        getProblemList();
      } else {
        ElMessage.error(res.data.code + ":" + res.data.message);
      }
    });
  }
  if (problemDialogType.value === 1) {
    //构造文件上传

    const formData = new FormData();
    const files = folderInput.value.files;
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    const loading = ElLoading.service({
        text: "上传文件。。。",
        background: "rgba(255, 255, 255, 0.6)",
        target: "#dialog",
      });
    api.post("/problem/save", problemDialogInfo).then((res) => {
      if (res.data.code === 200) {
        // 上传文件
        
        formData.append("path", "/"+res.data.data);
        api
          .post("/problem/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            if (res.data.code === 200) {
              ElMessage.success({
                message: "操作成功",
                grouping: true,
              });
              problemDialog.value = false;
              loading.close();
              getProblemList();
            }
          });
      }
    });
  }
};

const problemDialogClose = () => {
  Object.assign(problemDialogInfo, {
    problemId: "",
    title: "",
    description: "",
    time: null,
    tags: "",
    path: "", // Reset the path field
  });
};

const deleteProblem = (row) => {
  api.post("/problem/delete", { problemId: row.problemId }).then((res) => {
    if (res.data.code === 200) {
      ElMessage.success("删除成功");
      getProblemList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const handleDelete = (row) => {
  ElMessageBox.confirm("此操作将永久删除该问题，是否继续？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      deleteProblem(row);
    })
    .catch(() => {});
};

const handleMultipleDelete = () => {
  ElMessageBox.confirm(
    "此操作将永久删除下列的问题，是否继续？<br>" +
      multipleSelection.value.map((item) => item.title).join("<br>"),
    "提示",
    {
      dangerouslyUseHTMLString: true,
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }
  )
    .then(() => {
      multipleSelection.value.forEach((item) => {
        deleteProblem(item);
      });
    })
    .catch(() => {});
};

onMounted(() => {
  getProblemList();
});
</script>

<style scoped>
.adminProblem {
  padding: 10px 20px;
}
.pagination {
  padding: 10px 20px;
}
</style>
