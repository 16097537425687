<template>
  <div class="adminUser">
    <div>
      <el-form inline>
        <el-form-item>
          <el-input v-model="filterInfo.userId" placeholder="用户ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.username" placeholder="用户名" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.nick" placeholder="昵称" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.email" placeholder="邮箱"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="filterInfo.userType" placeholder="用户类型">
            <el-option label="管理员" :value="0" />
            <el-option label="学生" :value="1" />
            <el-option label="教师" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="filterChange">筛选</el-button>
          <el-button @click="resetFilter">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData.list"
      style="width: 100%"
      @row-click="handleRowClick"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
    >
      <el-table-column
        type="selection"
        :selectable="(row, index) => row.userType != 0"
        width="55"
      />
      <el-table-column
        prop="userId"
        sortable="custom"
        label="用户ID"
        width="100px"
      />
      <el-table-column prop="username" sortable="custom" label="用户名" />
      <el-table-column prop="nick" sortable="custom" label="昵称" />
      <el-table-column prop="email" sortable="custom" label="邮箱地址" />
      <el-table-column prop="userType" label="用户类型">
        <template #default="scope">
          <el-tag
            :type="['warning', 'success', 'primary'][scope.row.userType]"
            disable-transitions
          >
            {{ ["管理员", "学生", "教师"][scope.row.userType] }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="right" width="300">
        <template #header>
          <el-button @click="openDialog">批量学生</el-button>
          <el-button type="primary" @click="handleAdd">新增</el-button>
          <el-button
            type="danger"
            :disabled="multipleSelection.length == 0"
            @click="handleMultipleDelete"
          >
            删除选中
          </el-button>
        </template>
        <template #default="scope">
          <el-button
            size="small"
            @click.stop="handleEdit(scope.$index, scope.row)"
          >
            Edit
          </el-button>
          <el-button
            size="small"
            type="danger"
            @click.stop="handleDelete(scope.row)"
            :disabled="scope.row.userType == 0"
          >
            Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next ,total,sizes"
        :total="tableData.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        @prev-click="handlePrev"
        @next-click="handleNext"
      />
    </div>
    <!-- 新增 -->
    <el-dialog
      v-model="userDialog"
      :title="userDialogType == 1 ? '新增用户' : '修改用户'"
      width="400px"
      @close="userDialogClose"
    >
      <el-form>
        <el-form-item v-if="userDialogType == 2">
          <el-input
            disabled
            v-model="userDialogInfo.userId"
            placeholder="用户ID"
          />
        </el-form-item>
        <el-form-item>
          <el-input v-model="userDialogInfo.username" placeholder="用户名" />
        </el-form-item>
        <el-form-item
          v-model="userDialogInfo.password"
          placholder="密码"
          v-if="userDialogType == 1"
        >
          <el-input v-model="userDialogInfo.password" placeholder="密码" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="userDialogInfo.nick" placeholder="昵称" />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="userDialogInfo.email"
            placeholder="邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="userDialogInfo.userType" placeholder="用户类型">
            <el-option label="管理员" :value="0" />
            <el-option label="学生" :value="1" />
            <el-option label="教师" :value="2" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="userDialog = false">取消</el-button>
        <el-button
          type="primary"
          style="float: right"
          @click="handleUserDialogSubmit"
          :disabled="userSubmitBtnDisabled"
          >{{ userDialogType == 1 ? "新增" : "修改" }}</el-button
        >
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible" title="选择Excel文件">
      <p>每条数据一行，第一行不设置默认["username","pwd","nick","email"]</p>
      <p>也可以自行设置</p>
      <input
        type="file"
        ref="fileInputRef"
        @change="handleFileChange"
        accept=".xls, .xlsx"
      />
      <template #footer>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitFile">
          确认
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
 
<script setup>
//page,limit,sort,filter,search
//单选，多选
import { ref, reactive, onMounted, computed } from "vue";
import { api } from "@/utils/requests";
import { ElMessage, ElMessageBox } from "element-plus";
import isEqual from "lodash/isEqual";

//#region 数据获取
let page = ref(1);
let limit = ref(10);
let tableData = reactive({
  total: 0,
  list: [],
});
//
let sortInfo = reactive({
  prop: "",
  order: 0,
});
let filterInfo = reactive({
  userId: "",
  username: "",
  nick: "",
  email: "",
  userType: null,
});

// 获取数据
const getUserList = () => {
  api.post("/user/list", { page, limit, sortInfo, filterInfo }).then((res) => {
    if (res.data.code == 200) {
      Object.assign(tableData, res.data.data);
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};
//筛选，排序
const handleSortChange = (e) => {
  sortInfo.prop = e.prop;
  sortInfo.order =
    e.order === "ascending" ? 1 : e.order === "descending" ? -1 : 0;
  getUserList();
};
const filterChange = () => {
  getUserList();
};
const resetFilter = () => {
  filterInfo.userId = "";
  filterInfo.username = "";
  filterInfo.nick = "";
  filterInfo.email = "";
  filterInfo.userType = null;
  getUserList();
}
//改变页码
const handleCurrentChange = (e) => {
  page.value = e;
  getUserList();
};
//改变页数限制
const handleSizeChange = (e) => {
  limit.value = e;
  page.value = 1;
  getUserList();
};
//上一页
const handlePrev = (e) => {
  page.value = e;
  getUserList();
};
//下一页
const handleNext = (e) => {
  page.value = e;
  getUserList();
};

//#endregion

//#region单选，多选
let currentRow = ref(null);
let multipleSelection = ref([]);
// 单选
const handleRowClick = (e) => {
  currentRow.value = e;
  Object.assign(userDialogInfo, e);
  userDialogType.value = 2;
  userDialog.value = true;
  // 打开编辑
};
const handleEdit = (index, row) => {
  handleRowClick(row);
};
// 多选
const handleSelectionChange = (e) => {
  multipleSelection.value = e;
};
//#endregion

//#region 编辑修改
let userDialog = ref(false);
let userDialogType = ref(1);
let userDialogInfo = reactive({
  userId: "",
  username: "",
  password: "",
  nick: "",
  email: "",
  userType: null,
});
const userSubmitBtnDisabled = computed(() => {
  console.log(userDialogInfo, currentRow);
  console.log(isEqual(userDialogInfo, currentRow));
  return userDialogType == 2 && isEqual(userDialogInfo, currentRow);
});

const handleAdd = () => {
  userDialogType.value = 1;
  userDialog.value = true;
};
const handleUserDialogSubmit = () => {
  api.post("/user/save", userDialogInfo).then((res) => {
    if (res.data.code == 200) {
      ElMessage.success({
        message: "操作成功",
        grouping: true,
      });
      userDialog.value = false;
      getUserList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};
const userDialogClose = () => {
  Object.assign(userDialogInfo, {
    userId: "",
    username: "",
    nick: "",
    email: "",
    userType: null,
  });
};
const deleteUser = (row) => {
  api.post("/user/delete", { userid: row.userId }).then((res) => {
    if (res.data.code == 200) {
      ElMessage.success("删除成功");
      getUserList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};
const handleDelete = (row) => {
  ElMessageBox.confirm("此操作将永久删除该用户，是否继续？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    deleteUser(row);
  }).catch(() => {});
};
const handleMultipleDelete = () => {
  ElMessageBox.confirm(
    "此操作将永久删除下列的用户，是否继续？<br>" +
      multipleSelection.value.map((item) => item.username).join("<br>"),
    "提示",
    {
      dangerouslyUseHTMLString: true,
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }
  ).then(() => {
    multipleSelection.value.forEach((item) => {
      deleteUser(item);
    });
  }).catch(() => {});
};
//#endregion

//批量添加
const dialogVisible = ref(false);
const fileInputRef = ref(null);
let selectedFile = null;

const openDialog = () => {
  dialogVisible.value = true;
};

const handleFileChange = () => {
  selectedFile = fileInputRef.value.files[0];
};

const submitFile = () => {
  if (selectedFile) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      batchSave(jsonData);
    };
    reader.readAsArrayBuffer(selectedFile);
    dialogVisible.value = false;
  }
};
const batchSave = (data) => {
  let keys=["username","pwd","nick","email"];
  if(isArrayEqual(data[0],keys)){
    keys=data[0];
    data=data.slice(1);
  }
  data.forEach((item)=>{
    //跳过空行
    if(item.length==0){
      return
    }
    userDialogInfo={
    username:item[keys.indexOf("username")],
    password:item[keys.indexOf("password")],
    nick:item[keys.indexOf("nick")],
    email:item[keys.indexOf("email")],
    userType:1
  }
  handleUserDialogSubmit();
  })
  
}
function isArrayEqual(arr1, arr2) {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    // 判断两个集合的大小是否相等
    if (set1.size !== set2.size) {
        return false;
    }
    // 检查集合是否相等
    for (const item of set1) {
        if (!set2.has(item)) {
            return false;
        }
    }
    return true;
}
onMounted(() => {
  getUserList();
});
</script>
<style scoped>
.adminUser {
  padding: 10px 20px;
}
.pagination {
  padding: 10px 20px;
}
</style>