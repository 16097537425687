<template>
  <div class="adminCourseProblem">
    <div>
      <el-form inline>
        <el-form-item>
          <el-input v-model="filterInfo.classProblemId" placeholder="关系ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.classId" placeholder="班级ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.problemId" placeholder="问题ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.sort" placeholder="sort" />
        </el-form-item>
        <el-form-item>
          <el-button @click="filterChange">筛选</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData.list"
      style="width: 100%"
      @row-click="handleRowClick"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
    >
      <el-table-column
        type="selection"
        :selectable="(row, index) => true"
        width="55"
      />
      <el-table-column
        prop="classProblemId"
        sortable="custom"
        label="关系ID"
        width="100px"
      />
      <el-table-column prop="classId" sortable="custom" label="班级ID" />
      <el-table-column prop="className" label="班级名" />
      <el-table-column prop="problemId" sortable="custom" label="问题ID" />
      <el-table-column prop="problemTitle" label="题目" />
      <el-table-column prop="sort" sortable="custom" label="sort" />
      <el-table-column align="right" width="300">
        <template #header>
          <el-button type="primary" @click="handleAdd">新增</el-button>
          <el-button
            type="danger"
            :disabled="multipleSelection.length == 0"
            @click="handleMultipleDelete"
          >
            删除选中
          </el-button>
        </template>
        <template #default="scope">
          <el-button
            size="small"
            @click.stop="handleEdit(scope.$index, scope.row)"
          >
            Edit
          </el-button>
          <el-button
            size="small"
            type="danger"
            @click.stop="handleDelete(scope.row)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next ,total,sizes"
        :total="tableData.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        @prev-click="handlePrev"
        @next-click="handleNext"
      />
    </div>
    <!-- 新增 -->
    <el-dialog
      v-model="courseProblemDialog"
      :title="courseProblemDialogType == 1 ? '关系' : '关系'"
      width="400px"
      @close="courseProblemDialogClose"
    >
      <el-form>
        <el-form-item v-if="courseProblemDialogType == 2">
          <el-input
            disabled
            v-model="courseProblemDialogInfo.classProblemId"
            placeholder="ID"
          />
        </el-form-item>
        <el-form-item>
          <el-input v-model="courseProblemDialogInfo.classId" placeholder="班级ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="courseProblemDialogInfo.problemId" placeholder="问题ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="courseProblemDialogInfo.sort" placeholder="sort" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="courseProblemDialog = false">取消</el-button>
        <el-button
          type="primary"
          style="float: right"
          @click="handleCourseProblemDialogSubmit"
          :disabled="courseProblemSubmitBtnDisabled"
        >{{ courseProblemDialogType == 1 ? "新增" : "修改" }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { api } from "@/utils/requests";
import { ElMessage, ElMessageBox } from "element-plus";
import isEqual from "lodash/isEqual";

let page = ref(1);
let limit = ref(10);
let tableData = reactive({
  total: 0,
  list: [],
});
let sortInfo = reactive({
  prop: "",
  order: 0,
});
let filterInfo = reactive({
  classProblemId: "",
  classId: "",
  problemId: "",
  sort: null,
});

const getCourseProblemList = () => {
  api.post("/classProblem/list", { page, limit, sortInfo, filterInfo }).then((res) => {
    if (res.data.code === 200) {
      Object.assign(tableData, res.data.data);
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const handleSortChange = (e) => {
  sortInfo.prop = e.prop;
  sortInfo.order =
    e.order === "ascending" ? 1 : e.order === "descending" ? -1 : 0;
  getCourseProblemList();
};

const filterChange = () => {
  getCourseProblemList();
};

const handleCurrentChange = (e) => {
  page.value = e;
  getCourseProblemList();
};

const handleSizeChange = (e) => {
  limit.value = e;
  page.value = 1;
  getCourseProblemList();
};

const handlePrev = (e) => {
  page.value = e - 1;
  getCourseProblemList();
};

const handleNext = (e) => {
  page.value = e + 1;
  getCourseProblemList();
};

let currentRow = ref(null);
let multipleSelection = ref([]);

const handleRowClick = (e) => {
  currentRow.value = e;
  Object.assign(courseProblemDialogInfo, e);
  courseProblemDialogType.value = 2;
  courseProblemDialog.value = true;
};

const handleEdit = (index, row) => {
  handleRowClick(row);
};

const handleSelectionChange = (e) => {
  multipleSelection.value = e;
};

let courseProblemDialog = ref(false);
let courseProblemDialogType = ref(1);
let courseProblemDialogInfo = reactive({
  classProblemId: "",
  classId: "",
  problemId: "",
  sort: null,
});

const courseProblemSubmitBtnDisabled = computed(() => {
  return courseProblemDialogType == 2 && isEqual(courseProblemDialogInfo, currentRow);
});

const handleAdd = () => {
  courseProblemDialogType.value = 1;
  courseProblemDialog.value = true;
};

const handleCourseProblemDialogSubmit = () => {
  api.post("/classProblem/save", courseProblemDialogInfo).then((res) => {
    if (res.data.code === 200) {
      ElMessage.success({
        message: "操作成功",
        grouping: true,
      });
      courseProblemDialog.value = false;
      getCourseProblemList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const courseProblemDialogClose = () => {
  Object.assign(courseProblemDialogInfo, {
    classProblemId: "",
    classId: "",
    problemId: "",
    sort: null,
  });
};

const deleteCourseProblem = (row) => {
  api.post("/classProblem/delete", { classProblemId: row.classProblemId }).then((res) => {
    if (res.data.code === 200) {
      ElMessage.success("删除成功");
      getCourseProblemList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const handleDelete = (row) => {
  ElMessageBox.confirm("此操作将永久删除该关系，是否继续？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      deleteCourseProblem(row);
    })
    .catch(() => {});
};

const handleMultipleDelete = () => {
  ElMessageBox.confirm(
    "此操作将永久删除下列的关系，是否继续？<br>" +
      multipleSelection.value.map((item) => `ID:${item.classProblemId}`).join("<br>"),
    "提示",
    {
      dangerouslyUseHTMLString: true,
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }
  )
    .then(() => {
      multipleSelection.value.forEach((item) => {
        deleteCourseProblem(item);
      });
    })
    .catch(() => {});
};

onMounted(() => {
  getCourseProblemList();
});
</script>

<style scoped>
.adminCourseProblem {
  padding: 10px 20px;
}
.pagination {
  padding: 10px 20px;
}
</style>
