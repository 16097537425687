<template>
  <div class="adminClass">
    <div>
      <el-form inline>
        <el-form-item>
          <el-input v-model="filterInfo.classId" placeholder="班级ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.className" placeholder="班级名称" />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filterInfo.start"
            placeholder="from"
            type="datetime"
          />
          <span style="margin: 0 10px">-</span>
          <el-date-picker
            v-model="filterInfo.end"
            placeholder="to"
            type="datetime"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="filterChange">筛选</el-button>
          <el-button @click="resetFilter">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData.list"
      style="width: 100%"
      @row-click="handleRowClick"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
    >
      <el-table-column
        type="selection"
        :selectable="(row, index) => true"
        width="55"
      />
      <el-table-column
        prop="classId"
        sortable="custom"
        label="班级ID"
        width="100px"
      />
      <el-table-column prop="className" sortable="custom" label="班级名称" />
      <el-table-column prop="time" label="创建时间" sortable="custom">
        <template #default="scope">
          {{ new Date(scope.row.time).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template #header>
          <el-button type="primary" @click="handleAdd">新增</el-button>
          <el-button
            type="danger"
            :disabled="multipleSelection.length === 0"
            @click="handleMultipleDelete"
          >
            删除选中
          </el-button>
        </template>
        <template #default="scope">
          <el-button
            size="small"
            @click.stop="handleEdit(scope.$index, scope.row)"
          >
            Edit
          </el-button>
          <el-button
            size="small"
            type="danger"
            @click.stop="handleDelete(scope.row)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next, total, sizes"
        :total="tableData.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        @prev-click="handlePrev"
        @next-click="handleNext"
      />
    </div>
    <!-- 新增/编辑 -->
    <el-dialog
      v-model="classDialog"
      :title="classDialogType === 1 ? '新增班级' : '修改班级'"
      width="400px"
      @close="classDialogClose"
    >
      <el-form>
        <el-form-item v-if="classDialogType === 2">
          <el-input
            disabled
            v-model="classDialogInfo.classId"
            placeholder="班级ID"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="classDialogInfo.className"
            placeholder="班级名称"
          />
        </el-form-item>
        <el-form-item v-if="classDialogType === 2">
          <el-date-picker
            disabled
            v-model="classDialogInfo.time"
            type="datetime"
            placeholder="时间"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="classDialog = false">取消</el-button>
        <el-button
          type="primary"
          style="float: right"
          @click="handleClassDialogSubmit"
          :disabled="classSubmitBtnDisabled"
        >
          {{ classDialogType === 1 ? "新增" : "修改" }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { api } from "@/utils/requests";
import { ElMessage, ElMessageBox } from "element-plus";
import isEqual from "lodash/isEqual";

let page = ref(1);
let limit = ref(10);
let tableData = reactive({
  total: 0,
  list: [],
});

let sortInfo = reactive({
  prop: "",
  order: 0,
});
let filterInfo = reactive({
  classId: "",
  className: "",
  start: null,
  end: null,
});

const getClassList = () => {
  api.post("/class/list", { page, limit, sortInfo, filterInfo }).then((res) => {
    if (res.data.code === 200) {
      Object.assign(tableData, res.data.data);
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const handleSortChange = (e) => {
  sortInfo.prop = e.prop;
  sortInfo.order =
    e.order === "ascending" ? 1 : e.order === "descending" ? -1 : 0;
  getClassList();
};

const filterChange = () => {
  console.log(sortInfo, filterInfo);
  getClassList();
};
const resetFilter = () => {
  filterInfo.classId = "";
  filterInfo.className = "";
  filterInfo.start = null;
  filterInfo.end = null;
  getClassList();
};
const handleCurrentChange = (e) => {
  page.value = e;
  getClassList();
};

const handleSizeChange = (e) => {
  limit.value = e;
  page.value = 1;
  getClassList();
};

const handlePrev = (e) => {
  page.value = e;
  getClassList();
};

const handleNext = (e) => {
  page.value = e;
  getClassList();
};

let currentRow = ref(null);
let multipleSelection = ref([]);

const handleRowClick = (e) => {
  currentRow.value = e;
  Object.assign(classDialogInfo, e);
  classDialogType.value = 2;
  classDialog.value = true;
};

const handleEdit = (index, row) => {
  handleRowClick(row);
};

const handleSelectionChange = (e) => {
  multipleSelection.value = e;
};

let classDialog = ref(false);
let classDialogType = ref(1);
let classDialogInfo = reactive({
  classId: "",
  className: "",
  time: null,
});

const classSubmitBtnDisabled = computed(() => {
  return classDialogType === 2 && isEqual(classDialogInfo, currentRow);
});

const handleAdd = () => {
  classDialogInfo.time = new Date();
  classDialogType.value = 1;
  classDialog.value = true;
};

const handleClassDialogSubmit = () => {
  api.post("/class/save", classDialogInfo).then((res) => {
    if (res.data.code === 200) {
      ElMessage.success({
        message: "操作成功",
        grouping: true,
      });
      classDialog.value = false;
      getClassList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const classDialogClose = () => {
  Object.assign(classDialogInfo, {
    classId: "",
    className: "",
    time: null,
  });
};

const deleteClass = (row) => {
  api.post("/class/delete", { classId: row.classId }).then((res) => {
    if (res.data.code === 200) {
      ElMessage.success("删除成功");
      getClassList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const handleDelete = (row) => {
  ElMessageBox.confirm("此操作将永久删除该班级，是否继续？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    deleteClass(row);
  });
};

const handleMultipleDelete = () => {
  ElMessageBox.confirm(
    "此操作将永久删除下列的班级，是否继续？<br>" +
      multipleSelection.value.map((item) => item.className).join("<br>"),
    "提示",
    {
      dangerouslyUseHTMLString: true,
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }
  ).then(() => {
    multipleSelection.value.forEach((item) => {
      deleteClass(item);
    });
  });
};

onMounted(() => {
  getClassList();
});
</script>

<style scoped>
.adminClass {
  padding: 10px 20px;
}
.pagination {
  padding: 10px 20px;
}
</style>
