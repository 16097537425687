<template>
  <!-- 主容器 -->
  <div class="admin">
    <el-container style="height: 100vh">
      <!-- 头部 -->
      <el-header class="header">
        <div class="logo">
          <!-- 折叠按钮 -->
          <el-button
            style="
              border: none;
              height: calc(var(--el-header-height) - 2px);
              width: 64px;
            "
            @click="isCollapse = !isCollapse"
          >
            <el-icon v-if="!isCollapse" size="20"><Fold /></el-icon>
            <el-icon v-else size="20"><Expand /></el-icon>
          </el-button>
          <!-- 网站标题 -->
          <h2 style="margin: 0 0 0 10px">python兴趣学习平台 - 后台管理</h2>
        </div>
        <!-- 用户信息下拉菜单 -->
        <div
          class="content"
          style="display: flex; align-items: center; gap: 10px"
        >
          <p>{{ username }}</p>
          <el-dropdown trigger="click">
            <el-button circle :icon="MoreFilled"></el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="addTab(7)">个人信息</el-dropdown-item>
                <router-link to="/student" style="text-decoration: none">
                  <el-dropdown-item>切换学生</el-dropdown-item>
                </router-link>
                <router-link to="/teacher" style="text-decoration: none">
                  <el-dropdown-item>切换教师</el-dropdown-item>
                </router-link>
                <el-dropdown-item divided @click="logout()"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <!-- 侧边栏和主体内容 -->
      <el-container>
        <!-- 侧边栏 -->
        <el-aside width="fit-content">
          <el-menu
            default-active="1"
            :collapse="isCollapse"
            class="asideMenu"
            style="height: 100%"
          >
            <el-menu-item index="1" @click="addTab(0)">
              <el-icon><home-filled /></el-icon>
              <template #title>概览</template>
            </el-menu-item>
            <!-- 用户管理标签 -->
            <el-menu-item index="2" @click="addTab(1)">
              <el-icon><avatar /></el-icon>
              <template #title>用户管理</template>
            </el-menu-item>
            <!-- 班级管理标签 -->
            <el-menu-item index="3" @click="addTab(2)">
              <el-icon><icon-menu /></el-icon>
              <template #title>班级管理</template>
            </el-menu-item>
            <!-- 课程管理标签 -->
            <el-menu-item index="4" @click="addTab(3)">
              <el-icon><document /></el-icon>
              <template #title>课程管理</template>
            </el-menu-item>
            <!-- 题库管理标签 -->
            <el-menu-item index="5" @click="addTab(4)">
              <el-icon><folderOpened /></el-icon>
              <template #title>题库管理</template>
            </el-menu-item>
            <!-- 答题记录标签 -->
            <el-menu-item index="6" @click="addTab(5)">
              <el-icon><edit /></el-icon>
              <template #title>答题记录</template>
            </el-menu-item>
            <!-- 讨论管理标签 -->
            <el-menu-item index="7" @click="addTab(6)">
              <el-icon><chatSquare /></el-icon>
              <template #title>讨论管理</template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <!-- 主体内容区域 -->
        <el-main class="main">
          <el-tabs
            v-model="editableTabsValue"
            type="border-card"
            closable
            @tab-remove="removeTab"
            @tab-change="changeTab"
          >
            <!-- 可编辑的标签页 -->
            <el-tab-pane
              v-for="item in editableTabs"
              :key="item.name"
              :label="item.title"
              :name="item.name"
            >
              <!-- 动态组件，根据当前标签页的内容渲染对应组件 -->
              <component :is="item.content" @tab="childChangeTab"></component>
            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script setup>
import { reactive, ref, onMounted } from "vue";
import { api } from "@/utils/requests";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { getCookieValue } from "@/utils/cookies";
import {
  HomeFilled,
  Avatar,
  MoreFilled,
  FolderOpened,
  Fold,
  Expand,
  Document,
  Menu as IconMenu,
  Edit,
  ChatSquare,
  Location,
  Setting,
} from "@element-plus/icons-vue";
import Overview from "./Overview.vue";
import AdminUser from "./AdminUser.vue";
import AdminClass from "./AdminClass.vue";
import AdminCourse from "./AdminCourse.vue";
import AdminProblems from "./AdminProblem.vue";
import AdminAnswer from "./AdminAnswer.vue";
import AdminComment from "./AdminComment.vue";
import Info from "../account/Info.vue";

// 变量声明
const isCollapse = ref(false);

const panes = reactive([
  { component: Overview, label: "概览" },
  { component: AdminUser, label: "用户管理" },
  { component: AdminClass, label: "班级管理" },
  { component: AdminCourse, label: "课程管理" },
  { component: AdminProblems, label: "题库管理" },
  { component: AdminAnswer, label: "答题记录" },
  { component: AdminComment, label: "讨论管理" },
  { component: Info, label: "个人信息" },
]);
const editableTabsValue = ref("0");
let editableTabs = ref([
  {
    title: "概览",
    name: "0",
    content: Overview,
  },
]);
const router = useRouter();
let usertype = ref(getCookieValue("usertype"));
let username = ref(getCookieValue("username"));
//
const changeTab = (value) => {
  console.log(value)
  setActive(value);
};
const setActive = (value) => {
  let menu = document.querySelector(".el-menu");
  // menu子元素全部去掉is-active css属性
  menu.querySelectorAll(".el-menu-item").forEach((item) => {
    item.classList.remove("is-active");
  });
  // 第value个子元素添加is-active属性
  menu = menu.querySelectorAll(".el-menu-item")[value];
  if (menu) {
    menu.classList.add("is-active");
  }
};
// 添加标签页
const addTab = (id) => {
  setActive(id);
  // 查找是否存在相同标签的标签页
  const existingTab = editableTabs.value.find(
    (tab) => tab.title === panes[id].label
  );
  if (existingTab) {
    // 如果标签页已经存在，直接切换到该标签页
    editableTabsValue.value = existingTab.name;
  } else {
    // 否则，添加新的标签页
    editableTabs.value.push({
      title: panes[id].label,
      name: id,
      content: panes[id].component,
    });
    editableTabsValue.value = id;
  }
};
// 关闭标签页
const removeTab = (targetName) => {
  const tabs = editableTabs.value;
  let activeName = editableTabsValue.value;
  if (activeName === targetName) {
    tabs.forEach((tab, index) => {
      if (tab.name === targetName) {
        const nextTab = tabs[index + 1] || tabs[index - 1];
        if (nextTab) {
          setActive(nextTab.name);
          editableTabsValue.value = nextTab.name;
        }
      }
    });
  }
  editableTabs.value = tabs.filter((tab) => tab.name !== targetName);
};

const logout = () => {
  api.get("/user/logout").then((res) => {
    if (res.data.code == 200) {
      ElMessage.success("退出登陆");
      router.replace({ path: "/login" });
    }
  });
};

const childChangeTab = (e) => {
  addTab(e);
};

onMounted(() => {
  document.title = "python兴趣学习平台 - 管理员";
  let nav = document.querySelector(".el-aside");
  let main = document.querySelector(".main");
  let h = nav.offsetTop;

  window.addEventListener("scroll", function (e) {
    nav.style.height = "100%";
    if (window.pageYOffset > h) {
      nav.style.position = "fixed";
      nav.style.top = "0px";
      main.style.marginLeft = nav.offsetWidth + "px";
    } else {
      nav.style.position = "static";
      main.style.marginLeft = 0;
    }
  });
});
</script>


<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px 0 0;
  border-bottom: solid 1px rgb(200, 200, 200);
}
.logo {
  display: flex;
  align-items: center;
}
.main {
  padding: 0;
}
.asideMenu:not(.el-menu--collapse) {
  width: 200px;
}
</style>
<style>
.admin .el-tabs__header {
  margin-bottom: 0;
}
.admin .el-tabs {
  display: flex;
  flex-direction: column;
}
.admin .el-tabs--border-card {
  border: 0;
}
.admin .el-tabs__content {
  padding: 0;
}
.admin .el-tabs__content,
.el-tabs {
  height: 100%;
}
.admin .el-tab-pane {
  height: 100%;
}
.admin .el-tabs__nav div:first-child i {
  display: none;
}
</style>