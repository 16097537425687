<template>
  <div class="adminReplies">
    <div>
      <el-form inline>
        <el-form-item>
          <el-input v-model="filterInfo.replyId" placeholder="回复id" />
        </el-form-item>
        <el-form-item>
          <el-input disabled v-model="filterInfo.postId" placeholder="帖子id" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.userId" placeholder="用户id" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.content" placeholder="内容" />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filterInfo.start"
            type="datetime"
            placeholder="from"
          />
          <span style="margin: 0 10px">-</span>
          <el-date-picker
            v-model="filterInfo.end"
            type="datetime"
            placeholder="to"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="filterChange">筛选</el-button>
          <el-button @click="resetFilter">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData.list"
      style="width: 100%"
      @row-click="handleRowClick"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
    >
      <el-table-column
        type="selection"
        :selectable="(row, index) => true"
        width="55"
      />
      
      <el-table-column
        prop="replyId"
        sortable="custom"
        label="回复ID"
        width="100px"
      />
      <el-table-column prop="postId" label="帖子ID" />
      <el-table-column prop="userId" sortable="custom" label="用户ID" />
      <el-table-column prop="username" label="用户名" />
      <el-table-column prop="content" sortable="custom" label="内容" />
      <el-table-column prop="time" sortable="custom" label="时间">
        <template #default="scope">
          {{ new Date(scope.row.time).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="300">
        <template #header>
          <el-button type="primary" @click="handleAdd">新增</el-button>
          <el-button
            type="danger"
            :disabled="multipleSelection.length === 0"
            @click="handleMultipleDelete"
          >
            删除选中
          </el-button>
        </template>
        <template #default="scope">
          <el-button
            size="small"
            @click.stop="handleEdit(scope.$index, scope.row)"
          >
            Edit
          </el-button>
          <el-button
            size="small"
            type="danger"
            @click.stop="handleDelete(scope.row)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next, total, sizes"
        :total="tableData.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        @prev-click="handlePrev"
        @next-click="handleNext"
      />
    </div>
    <!-- 新增/编辑 -->
    <el-dialog
      v-model="postDialog"
      :title="postDialogType === 1 ? '新增回复' : '修改回复'"
      width="400px"
      @close="postDialogClose"
    >
      <el-form>
        <el-form-item v-if="postDialogType === 2">
          <el-input
            disabled
            v-model="postDialogInfo.replyId"
            placeholder="回复ID"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            disabled
            v-model="postDialogInfo.postId"
            placeholder="帖子ID"
          />
        </el-form-item>
        <el-form-item>
          <el-input v-model="postDialogInfo.userId" placeholder="用户ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="postDialogInfo.content" placeholder="内容" />
        </el-form-item>
        <el-form-item v-if="postDialogType === 2">
          <el-date-picker
            disabled
            v-model="postDialogInfo.time"
            type="datetime"
            placeholder="时间"
            style="width: 100%"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="postDialog = false">取消</el-button>
        <el-button
          type="primary"
          style="float: right"
          @click="handlePostDialogSubmit"
          :disabled="postSubmitBtnDisabled"
        >
          {{ postDialogType === 1 ? "新增" : "修改" }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed, defineProps } from "vue";
import { api } from "@/utils/requests";
import { ElMessage, ElMessageBox } from "element-plus";
import isEqual from "lodash/isEqual";

// Define the postId prop from the parent component
const { postId } = defineProps(["postId"]);

let page = ref(1);
let limit = ref(10);
let tableData = reactive({
  total: 0,
  list: [],
});
let sortInfo = reactive({
  prop: "",
  order: 0,
});
let filterInfo = reactive({
  replyId: "",
  postId: postId, // Use the prop value from the parent component
  userId: "",
  content: "",
  start: null,
  end: null,
});

const getRepliesList = () => {
  api
    .post("/reply/list", { page, limit, sortInfo, filterInfo })
    .then((res) => {
      if (res.data.code === 200) {
        Object.assign(tableData, res.data.data);
      } else {
        ElMessage.error(res.data.code + ":" + res.data.message);
      }
    });
};

const handleSortChange = (e) => {
  sortInfo.prop = e.prop;
  sortInfo.order =
    e.order === "ascending" ? 1 : e.order === "descending" ? -1 : 0;
  getRepliesList();
};

const filterChange = () => {
  getRepliesList();
};

const resetFilter = () => {
  filterInfo.replyId = "";
  filterInfo.userId = "";
  filterInfo.content = "";
  filterInfo.start = null;
  filterInfo.end = null;
  getRepliesList();
};

const handleCurrentChange = (e) => {
  page.value = e;
  getRepliesList();
};

const handleSizeChange = (e) => {
  limit.value = e;
  page.value = 1;
  getRepliesList();
};

const handlePrev = (e) => {
  page.value = e;
  getRepliesList();
};

const handleNext = (e) => {
  page.value = e;
  getRepliesList();
};

let currentRow = ref(null);
let multipleSelection = ref([]);

const handleRowClick = (e) => {
  currentRow.value = e;
  Object.assign(postDialogInfo, e);
  postDialogType.value = 2;
  postDialog.value = true;
};

const handleEdit = (index, row) => {
  handleRowClick(row);
};

const handleSelectionChange = (e) => {
  multipleSelection.value = e;
};

let postDialog = ref(false);
let postDialogType = ref(1);
let postDialogInfo = reactive({
  postId: postId,
  replyId: "",
  userId: "",
  content: "",
  time: null,
});

const postSubmitBtnDisabled = computed(() => {
  return postDialogType === 2 && isEqual(postDialogInfo, currentRow);
});

const handleAdd = () => {
  postDialogInfo.time = new Date();
  postDialogType.value = 1;
  postDialog.value = true;
};

const handlePostDialogSubmit = () => {
  api.post("/reply/save", postDialogInfo).then((res) => {
    if (res.data.code === 200) {
      ElMessage.success({
        message: "操作成功",
        grouping: true,
      });
      postDialog.value = false;
      getRepliesList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const postDialogClose = () => {
  Object.assign(postDialogInfo, {
    replyId: "",
    content: "",
    time: null,
  });
};

const deleteReply = (row) => {
  api.post("/reply/delete", { replyId: row.replyId }).then((res) => {
    if (res.data.code === 200) {
      ElMessage.success("删除成功");
      getRepliesList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const handleDelete = (row) => {
  ElMessageBox.confirm("此操作将永久删除该回复，是否继续？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      deleteReply(row);
    })
    .catch(() => {});
};

const handleMultipleDelete = () => {
  ElMessageBox.confirm(
    "此操作将永久删除下列的回复，是否继续？<br>" +
      multipleSelection.value.map((item) => item.content).join("<br>"),
    "提示",
    {
      dangerouslyUseHTMLString: true,
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }
  )
    .then(() => {
      multipleSelection.value.forEach((item) => {
        deleteReply(item);
      });
    })
    .catch(() => {});
};

onMounted(() => {
  getRepliesList();
});
</script>


<style scoped>
.adminPosts {
  padding: 10px 20px;
}
</style>
