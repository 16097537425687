<template>
  <div class="adminCourseUser">
    <div>
      <el-form inline>
        <el-form-item>
          <el-input v-model="filterInfo.userId" placeholder="用户ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.classId" placeholder="班级ID" />
        </el-form-item>
        <el-form-item>
          <el-select v-model="filterInfo.type" placeholder="类型">
            <el-option label="学生" :value="1" />
            <el-option label="教师" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="filterChange">筛选</el-button>
          <el-button @click="resetFilter">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData.list"
      style="width: 100%"
      @row-click="handleRowClick"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
    >
      <el-table-column
        type="selection"
        :selectable="(row, index) => row.type !== 0"
        width="55"
      />
      <el-table-column
        prop="id"
        sortable="custom"
        label="关系ID"
        width="100px"
      />
      <el-table-column prop="userId" sortable="custom" label="用户ID" />
      <el-table-column prop="nick" label="昵称" />
      <el-table-column prop="classId" sortable="custom" label="班级ID" />
      <el-table-column prop="className" label="班级名" />
      <el-table-column prop="type" label="类型">
        <template #default="scope">
          <el-tag
            :type="['success', 'primary'][scope.row.type-1]"
            disable-transitions
          >
            {{ ["学生", "教师"][scope.row.type-1] }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="right" width="300">
        <template #header>
          <el-button type="primary" @click="handleAdd">新增</el-button>
          <el-button
            type="danger"
            :disabled="multipleSelection.length == 0"
            @click="handleMultipleDelete"
          >
            删除选中
          </el-button>
        </template>
        <template #default="scope">
          <el-button
            size="small"
            @click.stop="handleEdit(scope.$index, scope.row)"
          >
            Edit
          </el-button>
          <el-button
            size="small"
            type="danger"
            @click.stop="handleDelete(scope.row)"
            :disabled="scope.row.type == 0"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next ,total,sizes"
        :total="tableData.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        @prev-click="handlePrev"
        @next-click="handleNext"
      />
    </div>
    <!-- 新增 -->
    <el-dialog
      v-model="courseUserDialog"
      :title="courseUserDialogType == 1 ? '新增' : '修改'"
      width="400px"
      @close="courseUserDialogClose"
    >
      <el-form>
        <el-form-item v-if="courseUserDialogType == 2">
          <el-input
            disabled
            v-model="courseUserDialogInfo.id"
            placeholder="ID"
          />
        </el-form-item>
        <el-form-item>
          <el-input v-model="courseUserDialogInfo.userId" placeholder="用户ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="courseUserDialogInfo.classId" placeholder="班级ID" />
        </el-form-item>
        <el-form-item>
          <el-select v-model="courseUserDialogInfo.type" placeholder="类型">
            <el-option label="学生" :value="1" />
            <el-option label="教师" :value="2" />
            <!-- Add more options as needed -->
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="courseUserDialog = false">取消</el-button>
        <el-button
          type="primary"
          style="float: right"
          @click="handleCourseUserDialogSubmit"
          :disabled="courseUserSubmitBtnDisabled"
        >{{ courseUserDialogType == 1 ? "新增" : "修改" }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { api } from "@/utils/requests";
import { ElMessage, ElMessageBox } from "element-plus";
import isEqual from "lodash/isEqual";

let page = ref(1);
let limit = ref(10);
let tableData = reactive({
  total: 0,
  list: [],
});
let sortInfo = reactive({
  prop: "",
  order: 0,
});
let filterInfo = reactive({
  userId: "",
  classId: "",
  type: null,
});

const getCourseUserList = () => {
  api.post("/userClass/list", { page, limit, sortInfo, filterInfo }).then((res) => {
    if (res.data.code === 200) {
      Object.assign(tableData, res.data.data);
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const handleSortChange = (e) => {
  sortInfo.prop = e.prop;
  sortInfo.order =
    e.order === "ascending" ? 1 : e.order === "descending" ? -1 : 0;
  getCourseUserList();
};

const filterChange = () => {
  getCourseUserList();
};

const resetFilter = () => {
  filterInfo.userId = "";
  filterInfo.classId = "";
  filterInfo.type = null;
  getCourseUserList();
}
const handleCurrentChange = (e) => {
  page.value = e;
  getCourseUserList();
};

const handleSizeChange = (e) => {
  limit.value = e;
  page.value = 1;
  getCourseUserList();
};

const handlePrev = (e) => {
  page.value = e - 1;
  getCourseUserList();
};

const handleNext = (e) => {
  page.value = e + 1;
  getCourseUserList();
};

let currentRow = ref(null);
let multipleSelection = ref([]);

const handleRowClick = (e) => {
  currentRow.value = e;
  Object.assign(courseUserDialogInfo, e);
  courseUserDialogType.value = 2;
  courseUserDialog.value = true;
};

const handleEdit = (index, row) => {
  handleRowClick(row);
};

const handleSelectionChange = (e) => {
  multipleSelection.value = e;
};

let courseUserDialog = ref(false);
let courseUserDialogType = ref(1);
let courseUserDialogInfo = reactive({
  id: "",
  userId: "",
  classId: "",
  type: null,
});

const courseUserSubmitBtnDisabled = computed(() => {
  return courseUserDialogType == 2 && isEqual(courseUserDialogInfo, currentRow);
});

const handleAdd = () => {
  courseUserDialogType.value = 1;
  courseUserDialog.value = true;
};

const handleCourseUserDialogSubmit = () => {
  api.post("/userClass/save", courseUserDialogInfo).then((res) => {
    if (res.data.code === 200) {
      ElMessage.success("操作成功");
      courseUserDialog.value = false;
      getCourseUserList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const courseUserDialogClose = () => {
  Object.assign(courseUserDialogInfo, {
    id: "",
    userId: "",
    classId: "",
    type: null,
  });
};

const deleteCourseUser = (row) => {
  api.post("/userClass/delete", { id: row.id }).then((res) => {
    if (res.data.code === 200) {
      ElMessage.success({
        message: "删除成功",
        grouping: true,
      });
      getCourseUserList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const handleDelete = (row) => {
  ElMessageBox.confirm("此操作将永久删除该关系，是否继续？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      deleteCourseUser(row);
    })
    .catch(() => {});
};

const handleMultipleDelete = () => {
  ElMessageBox.confirm(
    "此操作将永久删除下列的关系，是否继续？<br>" +
      multipleSelection.value.map((item) => `ID:${item.id}`).join("<br>"),
    "提示",
    {
      dangerouslyUseHTMLString: true,
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }
  )
    .then(() => {
      multipleSelection.value.forEach((item) => {
        deleteCourseUser(item);
      });
    })
    .catch(() => {});
};

onMounted(() => {
  getCourseUserList();
});
</script>

<style scoped>
.adminCourseUser {
  padding: 10px 20px;
}
.pagination {
  padding: 10px 20px;
}
</style>
