<template>
  <div class="adminAnswer">
    <div>
      <!-- Filter Form -->
      <el-form inline>
        <el-form-item>
          <el-input v-model="filterInfo.id" placeholder="答案ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.userId" placeholder="用户ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.problemId" placeholder="题目ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.status" placeholder="得分" />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filterInfo.start"
            type="datetime"
            placeholder="from"
          />
          <span style="margin: 0 10px">-</span>
          <el-date-picker
            v-model="filterInfo.end"
            type="datetime"
            placeholder="to"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="filterChange">筛选</el-button>
          <el-button @click="resetFilter">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- Answer Table -->
    <el-table
      :data="tableData.list"
      style="width: 100%"
      @row-click="handleRowClick"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
    >
      <el-table-column
        type="selection"
        :selectable="(row, index) => true"
        width="55"
      />
      <el-table-column
        prop="answerId"
        sortable="custom"
        label="答案ID"
        width="100px"
      />
      <el-table-column prop="userId" sortable="custom" label="用户ID" />
      <el-table-column prop="nick" label="昵称" />
      <el-table-column prop="problemId" sortable="custom" label="问题ID" />
      <el-table-column prop="problemTitle" label="标题" />
      <!-- Remove code column -->
      <el-table-column prop="status" label="状态" sortable="custom" />
      <el-table-column prop="time" label="提交时间" sortable="custom">
        <template #default="scope">
          {{ new Date(scope.row.time).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column prop="score" label="分数" sortable="custom" />
       <el-table-column align="right">
        <template #header>
          <el-button
            type="danger"
            :disabled="multipleSelection.length === 0"
            @click="handleMultipleDelete"
          >
            删除选中
          </el-button>
        </template>
        <template #default="scope">
          <el-button
            size="small"
            type="danger"
            @click.stop="handleDelete(scope.row)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Pagination -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next, total, sizes"
        :total="tableData.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        @prev-click="handlePrev"
        @next-click="handleNext"
      />
    </div>

    <!-- Display Code Dialog -->
    <el-dialog
      v-model="dialogVisible"
      title="答案代码"
      width="80%"
      @close="dialogClose"
    >
      <pre>{{ currentRow ? currentRow.code : '' }}</pre>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { api } from "@/utils/requests";
import { ElMessage, ElMessageBox } from "element-plus";
import isEqual from "lodash/isEqual";

let page = ref(1);
let limit = ref(10);
let tableData = reactive({
  total: 0,
  list: [],
});

let sortInfo = reactive({
  prop: "",
  order: 0,
});
let filterInfo = reactive({
  id: "",
  userId: "",
  problemId:"",
  start:null,
  end:null
});

const getList = () => {
  api.post("/answer/list", {
    page,
    limit,
    sortInfo,
    filterInfo,
  }).then((res) => {
    if (res.data.code === 200) {
      Object.assign(tableData, res.data.data);
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const handleSortChange = (e) => {
  sortInfo.prop = e.prop;
  sortInfo.order =
    e.order === "ascending" ? 1 : e.order === "descending" ? -1 : 0;
  getList();
};

const filterChange = () => {
  getList();
};

const resetFilter = () => {
  filterInfo.id = "";
  filterInfo.userId = "";
  getList();
};

const handleCurrentChange = (e) => {
  page.value = e;
  getList();
};

const handleSizeChange = (e) => {
  limit.value = e;
  page.value = 1;
  getList();
};

const handlePrev = (e) => {
  page.value = e;
  getList();
};

const handleNext = (e) => {
  page.value = e;
  getList();
};

let currentRow = ref(null);
let multipleSelection = ref([]);

const handleRowClick = (row) => {
  currentRow.value = row;
  dialogVisible.value = true;
};

const handleSelectionChange = (e) => {
  multipleSelection.value = e;
};

let dialogVisible = ref(false);
let dialogType = ref(1);

const dialogClose = () => {
  currentRow.value = null;
  dialogVisible.value = false;
};
const deleteAnswer = (row) => {
  api.post("/answer/delete", { id: row.answerId }).then((res) => {
    if (res.data.code === 200) {
      ElMessage.success("删除成功");
      getList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

const handleDelete = (row) => {
  ElMessageBox.confirm("此操作将永久删除该记录，是否继续？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    deleteAnswer(row);
  });
};

const handleMultipleDelete = () => {
  ElMessageBox.confirm(
    "此操作将永久删除下列的记录，是否继续？<br>" +
      multipleSelection.value.map((item) => item.problemTitle+" "+item.nick).join("<br>"),
    "提示",
    {
      dangerouslyUseHTMLString: true,
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }
  ).then(() => {
    multipleSelection.value.forEach((item) => {
      deleteAnswer(item);
    });
  });
};

onMounted(() => {
  getList();
});
</script>

<style scoped>
.adminAnswer {
  padding: 10px 20px;
}
.pagination {
  padding: 10px 20px;
}
</style>
